// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './Home';
import Contact from './Contact';
import About from './About';
import Website from './Website';
import DigitalMenu from './DigitalMenu';
import DataEntry from './DataEntry';
import './Style.css';

function App() {
  return (
    <Router>
      <div>
        {/* Navigation Menu */}
        <nav>
          <ul className='menu-container'>
            <li className='menu-item'><Link to="/">Home</Link></li>
            <li className='menu-item'>
              <Link to="" className='solutionMenu'>Solutions</Link>
              <ul className='solutions-nestedMenu'>
                <li className='menu-item'><Link to="/website">Build a Website</Link></li>
                <li className='menu-item'><Link to="/digitalMenu">Create Digital Menu</Link></li>
                <li className='menu-item'><Link to="/dataEntry">Build Data Entry</Link></li>
              </ul>
            </li>
            <li className='menu-item'><Link to="/contact">Contact</Link></li>
            <li className='menu-item'><Link to="/about">About</Link></li>
          </ul>
        </nav>

        {/* Define Routes */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/website" element={<Website />} />
          <Route path="/digitalMenu" element={<DigitalMenu />} />
          <Route path="/dataEntry" element={<DataEntry />} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/about" element={ <About />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
