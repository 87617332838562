import React from 'react'
import './BuildWebsite.css';
import webImage from "./assets/website.png";

const Website = ()=> {
    return (
        <div className="build-website-container">
          <div className="content">
            <h2 className="headline">Build a Professional Website for Your Business</h2>
            <p className="description">
              In today's digital world, having a website for your business is more important than ever. Let us help you build a custom website that represents your brand and drives results.
            </p>
            <ul className="benefits-list">
              <li>🌐 Reach a global audience</li>
              <li>📈 Increase credibility and trust</li>
              <li>💻 Showcase your products/services 24/7</li>
              <li>📱 Mobile-friendly, responsive design</li>
            </ul>
            <p className="cta-text">
              Ready to take your business to the next level? Let’s get started today!
            </p>
            <a href='/contact' className="cta-button">Get a Quote</a>
          </div>
          <div className="image">
            <img
              src={webImage} 
              alt="Business Website"
              className="website-image"
            />
          </div>
        </div>
      );
}

export default Website;