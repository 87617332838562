// src/About.js
import React from 'react';

const About = () => {
  return (
    <div className='about-container'>
      
  <section class="about-container">
    <div class="about-content">
      <h1>About Us</h1>
      <p>At <strong>Tiktok Software</strong>, we are dedicated to empowering businesses with innovative and tailored digital solutions. Based in <strong>Melbourne, Australia</strong>, our mission is to help you streamline operations, improve efficiency, and enhance your customer experience in the digital age.</p>
      
      <h2>What We Do</h2>
      <p>We specialize in:</p>
      <ul>
        <li><strong>Digital Menus</strong>: Interactive and customizable digital menus that enhance your customers’ dining experience.</li>
        <li><strong>Website Development</strong>: Beautiful, functional websites that are designed to attract, engage, and convert visitors.</li>
        <li><strong>Data Entry Solutions</strong>: Efficient, accurate data entry services to keep your business organized and running smoothly.</li>
        <li><strong>Invoice Generators</strong>: Customizable invoice generation tools that make billing fast, easy, and professional.</li>
        <li><strong>Bespoke Software Solutions</strong>: Tailored applications and systems designed to meet your unique business needs.</li>
      </ul>

      <h2>Our Approach</h2>
      <p>Our team of experts works closely with you to understand your challenges and goals, delivering custom solutions that drive growth, efficiency, and success. Whether you're a small business or an established enterprise, we're here to help you navigate the digital world and stay ahead of the competition.</p>
    </div>
  </section>
    </div>
  );
};

export default About;
