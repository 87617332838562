import React from 'react'

const Contact = ()=> {
    return(
        <div className='contact-container'>
            <section class="contact-container">
                <div class="contact-content">
                <h1>Contact Us</h1>
                <p>We'd love to hear from you! Please feel free to reach out to us using the following contact details:</p>

                <h2>Email</h2>
                <p>For general inquiries, feel free to email us at: <a href="mailto:samirehsani19@gmail.com">samirehsani19@gmail.com</a></p>

                <h2>Phone</h2>
                <p>Call us for more information or to discuss your project: <a href="tel:0490868003">0490 868 003</a></p>

                <h2>Visit Us</h2>
                <p>Our office is located at:</p>
                <p>Tiktok Software, 104 Cross Street, Melbourne, VIC 3012, Australia</p>
                </div>
            </section>
            
        </div>
    )
}

export default Contact;