import React from 'react'
import dataImage from "./assets/data-entry.png";
import "./DataEntry.css";
const DataEntry = ()=> {
    return (
        <div className="data-entry-container">
          <div className="content">
            <h2 className="headline">Create a Custom Data Entry Form</h2>
            <p className="description">
              Simplify your data collection process with a custom-built data entry form. Whether you're gathering customer feedback, collecting survey data, or managing orders, we’ll create a form tailored to your needs.
            </p>
            <ul className="benefits-list">
              <li>📝 Customizable fields for any use case</li>
              <li>🔒 Secure and easy data collection</li>
              <li>📊 Instant results and analytics</li>
              <li>💻 Integrates with your system or database</li>
            </ul>
            <p className="cta-text">
              Ready to streamline your data collection? Let's build your custom data entry form now!
            </p>
            <a href='/contact' className="cta-button">Get a Quote</a>
          </div>
          <div className="image">
            <img
              src={dataImage}
              alt="Data Entry"
              className="data-entry-image"
            />
          </div>
        </div>
      );
    }
export default DataEntry;